/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}
html, body {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: auto;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.selected {
  background-color: black;
  color: white;
}
.ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.contact-info-column {
  width: 33%;
  overflow: auto;
}

.listbox li {
  cursor: pointer;
  padding: 2px 2px 0 7px;
  border-radius: 5px;
}
.h-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
  max-width: 100%;
}
.row-width {
  width: 100%;
  overflow: auto;
}
.overflow-auto {
  overflow: auto;
}
.overflow-none {
  overflow: none;
}
.disable-links {
  pointer-events: none;
  cursor: default;
}
.header {
  font-weight: bold;
  text-decoration: underline;
}

.sm-icon-btn {
  width: 20px !important;
  height: 20px !important;
  padding: 2px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
  }
  span {
    width: 20px !important;
    height: 20px !important;
  }
}
.md-icon-btn {
  width: 26px !important;
  height: 26px !important;
  padding: 2px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  mat-icon {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }
  span {
    width: 26px !important;
    height: 26px !important;
  }
}

button.mat-flat-button {
  margin-right: 10px;
}
.compact-menu .mat-menu-item {
  height: 24px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 24px;
}
.pointer {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
// SCROLLBAR CLASSES *********************************
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';